export const mockDataTeam = [
  {
    id: 1,
    name: "Reginaldo Rossi",
    email: "reginaldorossi@gmail.com",
    age: 70,
    phone: "(81) 99999-9999",
    access: "admin",
  },
  {
    id: 2,
    name: "Amado Batista",
    email: "amadobatista@gmail.com",
    age: 71,
    phone: "(62) 99999-9999",
    access: "admin",
  },
  {
    id: 3,
    name: "Waldick Soriano",
    email: "waldicksoriano@gmail.com",
    age: 75,
    phone: "(77) 99999-9999",
    access: "manager",
  },
  {
    id: 4,
    name: "Agnaldo Timóteo",
    email: "agnaldotimoteo@gmail.com",
    age: 84,
    phone: "(21) 99999-9999",
    access: "admin",
  },
  {
    id: 5,
    name: "Cauby Peixoto",
    email: "caubypeixoto@gmail.com",
    age: 85,
    phone: "(11) 99999-9999",
    access: "admin",
  },
  {
    id: 6,
    name: "Sidney Magal",
    email: "sidneymagal@gmail.com",
    age: 71,
    phone: "(21) 99999-9999",
    access: "admin",
  },
  {
    id: 7,
    name: "Odair José",
    email: "odairjose@gmail.com",
    age: 71,
    phone: "(62) 99999-9999",
    access: "user",
  },
  {
    id: 8,
    name: "Wando",
    email: "wando@gmail.com",
    age: 66,
    phone: "(31) 99999-9999",
    access: "user",
  },
  {
    id: 9,
    name: "Moacyr Franco",
    email: "moacyrfranco@gmail.com",
    age: 86,
    phone: "(11) 99999-9999",
    access: "manager",
  },
  {
    id: 10,
    name: "Genival Lacerda",
    email: "genivallacerda@gmail.com",
    age: 89,
    phone: "(83) 99999-9999",
    access: "user",
  },
  {
    id: 11,
    name: "Bartô Galeno",
    email: "bartogaleno@gmail.com",
    age: 70,
    phone: "(85) 99999-9999",
    access: "user",
  },
  {
    id: 12,
    name: "Nelson Ned",
    email: "nelsoned@gmail.com",
    age: 66,
    phone: "(11) 99999-9999",
    access: "admin",
  },
  {
    id: 13,
    name: "Falcão",
    email: "falcao@gmail.com",
    age: 64,
    phone: "(85) 99999-9999",
    access: "admin",
  },
];
